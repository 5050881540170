import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_sentryserver_0065e177 from 'nuxt_plugin_sentryserver_0065e177' // Source: ./sentry.server.js (mode: 'server')
import nuxt_plugin_sentryclient_caa72022 from 'nuxt_plugin_sentryclient_caa72022' // Source: ./sentry.client.js (mode: 'client')
import nuxt_plugin_webpush_3feba6f2 from 'nuxt_plugin_webpush_3feba6f2' // Source: ./web-push.js (mode: 'all')
import nuxt_plugin_index_335d4c3b from 'nuxt_plugin_index_335d4c3b' // Source: ./firebase/index.js (mode: 'all')
import nuxt_plugin_axios_5242f69a from 'nuxt_plugin_axios_5242f69a' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_vcalendarclient_4b80cac9 from 'nuxt_plugin_vcalendarclient_4b80cac9' // Source: ../plugins/v-calendar.client.js (mode: 'client')
import nuxt_plugin_plugin_4319c668 from 'nuxt_plugin_plugin_4319c668' // Source: ./plugin.ts (mode: 'all')
import nuxt_plugin_pluginsallowservices3f335c3c_51ba7220 from 'nuxt_plugin_pluginsallowservices3f335c3c_51ba7220' // Source: ./plugins.allow-services.3f335c3c.ts (mode: 'all')
import nuxt_plugin_pluginsapi2e8946dc_f7a59ef8 from 'nuxt_plugin_pluginsapi2e8946dc_f7a59ef8' // Source: ./plugins.api.2e8946dc.ts (mode: 'all')
import nuxt_plugin_pluginsaxios5c1768c0_97caa51c from 'nuxt_plugin_pluginsaxios5c1768c0_97caa51c' // Source: ./plugins.axios.5c1768c0.ts (mode: 'all')
import nuxt_plugin_pluginseventbusnew4f408954_70ff74de from 'nuxt_plugin_pluginseventbusnew4f408954_70ff74de' // Source: ./plugins.event-bus-new.4f408954.ts (mode: 'all')
import nuxt_plugin_pluginseventbus3e4626ee_32196494 from 'nuxt_plugin_pluginseventbus3e4626ee_32196494' // Source: ./plugins.event-bus.3e4626ee.ts (mode: 'all')
import nuxt_plugin_pluginsfloatingvuee8fe9cb6_ddae77a4 from 'nuxt_plugin_pluginsfloatingvuee8fe9cb6_ddae77a4' // Source: ./plugins.floating-vue.e8fe9cb6.ts (mode: 'all')
import nuxt_plugin_pluginsformatters0be4450a_4f2f1b96 from 'nuxt_plugin_pluginsformatters0be4450a_4f2f1b96' // Source: ./plugins.formatters.0be4450a.ts (mode: 'all')
import nuxt_plugin_pluginslastactivityclient2ed82711_6976e666 from 'nuxt_plugin_pluginslastactivityclient2ed82711_6976e666' // Source: ./plugins.last-activity.client.2ed82711.ts (mode: 'client')
import nuxt_plugin_pluginslastpaymentclient0e3ac02c_89d430f0 from 'nuxt_plugin_pluginslastpaymentclient0e3ac02c_89d430f0' // Source: ./plugins.last-payment.client.0e3ac02c.ts (mode: 'client')
import nuxt_plugin_pluginslazyloadclient584d72ec_625ac726 from 'nuxt_plugin_pluginslazyloadclient584d72ec_625ac726' // Source: ./plugins.lazy-load.client.584d72ec.ts (mode: 'client')
import nuxt_plugin_pluginsmaskaea73cc3e_44e892ec from 'nuxt_plugin_pluginsmaskaea73cc3e_44e892ec' // Source: ./plugins.maska.ea73cc3e.ts (mode: 'all')
import nuxt_plugin_pluginspluralendingse8dd6c7e_6b2227f4 from 'nuxt_plugin_pluginspluralendingse8dd6c7e_6b2227f4' // Source: ./plugins.plural-endings.e8dd6c7e.ts (mode: 'all')
import nuxt_plugin_pluginsvuelidatee98851b6_1e6d0a4c from 'nuxt_plugin_pluginsvuelidatee98851b6_1e6d0a4c' // Source: ./plugins.vuelidate.e98851b6.ts (mode: 'all')
import nuxt_plugin_pluginsyamaps6af34afa_f7c9b3ca from 'nuxt_plugin_pluginsyamaps6af34afa_f7c9b3ca' // Source: ./plugins.ya-maps.6af34afa.ts (mode: 'all')
import nuxt_plugin_authextendaxios30d8388a_7d06b3f7 from 'nuxt_plugin_authextendaxios30d8388a_7d06b3f7' // Source: ./auth.extend-axios.30d8388a.ts (mode: 'all')
import nuxt_plugin_authstore2805af4a_6ab38e4f from 'nuxt_plugin_authstore2805af4a_6ab38e4f' // Source: ./auth.store.2805af4a.ts (mode: 'all')
import nuxt_plugin_ymetricsclient_36a5c191 from 'nuxt_plugin_ymetricsclient_36a5c191' // Source: ./y-metrics.client.ts (mode: 'client')
import nuxt_plugin_gaclient_64a0b160 from 'nuxt_plugin_gaclient_64a0b160' // Source: ./ga.client.ts (mode: 'client')
import nuxt_plugin_analyticsclient_807b5e30 from 'nuxt_plugin_analyticsclient_807b5e30' // Source: ./analytics.client.ts (mode: 'client')
import nuxt_plugin_autopaymentplugina5acfcea_07323cf7 from 'nuxt_plugin_autopaymentplugina5acfcea_07323cf7' // Source: ./auto-payment.plugin.a5acfcea.ts (mode: 'all')
import nuxt_plugin_cartaxcalcstore27799c1a_5902ce93 from 'nuxt_plugin_cartaxcalcstore27799c1a_5902ce93' // Source: ./car-tax-calc.store.27799c1a.ts (mode: 'all')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root ? this.$root.$options.$nuxt : null
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule

function registerModule (path, rawModule, options = {}) {
  const preserveState = process.client && (
    Array.isArray(path)
      ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state)
      : path in this.state
  )
  return originalRegisterModule.call(this, path, rawModule, { preserveState, ...options })
}

async function createApp(ssrContext, config = {}) {
  const store = createStore(ssrContext)
  const router = await createRouter(ssrContext, config, { store })

  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  store.registerModule = registerModule

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"htmlAttrs":{"lang":"ru"},"title":"Проверка штрафов ГИБДД онлайн на официальном сайте","meta":[{"hid":"charset","charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"hid":"description","name":"description","content":"Сервис Paygibdd.ru поможет узнать о наличии штрафов в базе данных ГИС ГМП, ГИБДД и других и оплатить эти штрафы картой со скидкой 50%."},{"name":"format-detection","content":"telephone=no"},{"name":"format-detection","content":"address=no"},{"name":"HandheldFriendly","content":"true"},{"name":"X-UA-Compatible","content":"ie=edge"},{"name":"google-site-verification","content":"olfoNwPCJSSxDIpwYg-CTXnhNPo7PM1jSEZKFNt3REU"},{"hid":"og:title","name":"og:title","content":"Проверка штрафов ГИБДД онлайн на официальном сайте"},{"hid":"og:site_name","name":"og:site_name","content":"Оплата ГИБДД"},{"hid":"og:description","name":"og:description","content":"Сервис Paygibdd.ru поможет узнать о наличии штрафов в базе данных ГИС ГМП, ГИБДД и других и оплатить эти штрафы картой со скидкой 50%."}],"link":[{"rel":"icon","type":"image\u002Fx-icon","href":"\u002Ffavicon\u002Ffavicon.ico"}],"script":[{"src":"https:\u002F\u002Fpolyfill-fastly.io\u002Fv3\u002Fpolyfill.min.js?features=es6","body":true,"defer":true}],"style":[]},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    beforeSerializeFns: ssrContext ? ssrContext.beforeSerializeFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (process.server && typeof nuxt_plugin_sentryserver_0065e177 === 'function') {
    await nuxt_plugin_sentryserver_0065e177(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sentryclient_caa72022 === 'function') {
    await nuxt_plugin_sentryclient_caa72022(app.context, inject)
  }

  if (typeof nuxt_plugin_webpush_3feba6f2 === 'function') {
    await nuxt_plugin_webpush_3feba6f2(app.context, inject)
  }

  if (typeof nuxt_plugin_index_335d4c3b === 'function') {
    await nuxt_plugin_index_335d4c3b(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_5242f69a === 'function') {
    await nuxt_plugin_axios_5242f69a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_vcalendarclient_4b80cac9 === 'function') {
    await nuxt_plugin_vcalendarclient_4b80cac9(app.context, inject)
  }

  if (typeof nuxt_plugin_plugin_4319c668 === 'function') {
    await nuxt_plugin_plugin_4319c668(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginsallowservices3f335c3c_51ba7220 === 'function') {
    await nuxt_plugin_pluginsallowservices3f335c3c_51ba7220(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginsapi2e8946dc_f7a59ef8 === 'function') {
    await nuxt_plugin_pluginsapi2e8946dc_f7a59ef8(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginsaxios5c1768c0_97caa51c === 'function') {
    await nuxt_plugin_pluginsaxios5c1768c0_97caa51c(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginseventbusnew4f408954_70ff74de === 'function') {
    await nuxt_plugin_pluginseventbusnew4f408954_70ff74de(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginseventbus3e4626ee_32196494 === 'function') {
    await nuxt_plugin_pluginseventbus3e4626ee_32196494(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginsfloatingvuee8fe9cb6_ddae77a4 === 'function') {
    await nuxt_plugin_pluginsfloatingvuee8fe9cb6_ddae77a4(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginsformatters0be4450a_4f2f1b96 === 'function') {
    await nuxt_plugin_pluginsformatters0be4450a_4f2f1b96(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_pluginslastactivityclient2ed82711_6976e666 === 'function') {
    await nuxt_plugin_pluginslastactivityclient2ed82711_6976e666(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_pluginslastpaymentclient0e3ac02c_89d430f0 === 'function') {
    await nuxt_plugin_pluginslastpaymentclient0e3ac02c_89d430f0(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_pluginslazyloadclient584d72ec_625ac726 === 'function') {
    await nuxt_plugin_pluginslazyloadclient584d72ec_625ac726(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginsmaskaea73cc3e_44e892ec === 'function') {
    await nuxt_plugin_pluginsmaskaea73cc3e_44e892ec(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginspluralendingse8dd6c7e_6b2227f4 === 'function') {
    await nuxt_plugin_pluginspluralendingse8dd6c7e_6b2227f4(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginsvuelidatee98851b6_1e6d0a4c === 'function') {
    await nuxt_plugin_pluginsvuelidatee98851b6_1e6d0a4c(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginsyamaps6af34afa_f7c9b3ca === 'function') {
    await nuxt_plugin_pluginsyamaps6af34afa_f7c9b3ca(app.context, inject)
  }

  if (typeof nuxt_plugin_authextendaxios30d8388a_7d06b3f7 === 'function') {
    await nuxt_plugin_authextendaxios30d8388a_7d06b3f7(app.context, inject)
  }

  if (typeof nuxt_plugin_authstore2805af4a_6ab38e4f === 'function') {
    await nuxt_plugin_authstore2805af4a_6ab38e4f(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_ymetricsclient_36a5c191 === 'function') {
    await nuxt_plugin_ymetricsclient_36a5c191(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_gaclient_64a0b160 === 'function') {
    await nuxt_plugin_gaclient_64a0b160(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_analyticsclient_807b5e30 === 'function') {
    await nuxt_plugin_analyticsclient_807b5e30(app.context, inject)
  }

  if (typeof nuxt_plugin_autopaymentplugina5acfcea_07323cf7 === 'function') {
    await nuxt_plugin_autopaymentplugina5acfcea_07323cf7(app.context, inject)
  }

  if (typeof nuxt_plugin_cartaxcalcstore27799c1a_5902ce93 === 'function') {
    await nuxt_plugin_cartaxcalcstore27799c1a_5902ce93(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
