import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _05cbd3f5 = () => interopDefault(import('../pages/car-tax-landing.vue' /* webpackChunkName: "pages/car-tax-landing" */))
const _78e00660 = () => interopDefault(import('../pages/cities.vue' /* webpackChunkName: "pages/cities" */))
const _4f0a4d90 = () => interopDefault(import('../pages/confidential-policy.vue' /* webpackChunkName: "pages/confidential-policy" */))
const _6ecc7202 = () => interopDefault(import('../pages/contact-us.vue' /* webpackChunkName: "pages/contact-us" */))
const _6f10d446 = () => interopDefault(import('../pages/ctc-not-found.vue' /* webpackChunkName: "pages/ctc-not-found" */))
const _384108c2 = () => interopDefault(import('../pages/divisions.vue' /* webpackChunkName: "pages/divisions" */))
const _1d16aac2 = () => interopDefault(import('../pages/email-landing.vue' /* webpackChunkName: "pages/email-landing" */))
const _2138da62 = () => interopDefault(import('../pages/error-gibdd-incoming.vue' /* webpackChunkName: "pages/error-gibdd-incoming" */))
const _96d63e7e = () => interopDefault(import('../pages/expert.vue' /* webpackChunkName: "pages/expert" */))
const _d97b03ac = () => interopDefault(import('../pages/gibdd-incoming.vue' /* webpackChunkName: "pages/gibdd-incoming" */))
const _02fa187e = () => interopDefault(import('../pages/guarantee.vue' /* webpackChunkName: "pages/guarantee" */))
const _99714b6c = () => interopDefault(import('../pages/kak-oplatit.vue' /* webpackChunkName: "pages/kak-oplatit" */))
const _42928b00 = () => interopDefault(import('../pages/o-servise.vue' /* webpackChunkName: "pages/o-servise" */))
const _d128203e = () => interopDefault(import('../pages/osago/index.vue' /* webpackChunkName: "pages/osago/index" */))
const _107eac7f = () => interopDefault(import('../pages/payment.vue' /* webpackChunkName: "pages/payment" */))
const _7de72748 = () => interopDefault(import('../pages/payment-fail.vue' /* webpackChunkName: "pages/payment-fail" */))
const _fb793c74 = () => interopDefault(import('../pages/payment-history.vue' /* webpackChunkName: "pages/payment-history" */))
const _6c44f260 = () => interopDefault(import('../pages/payment-mobile.vue' /* webpackChunkName: "pages/payment-mobile" */))
const _21af6db5 = () => interopDefault(import('../pages/payment-success.vue' /* webpackChunkName: "pages/payment-success" */))
const _76e67aa2 = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _1079dd71 = () => interopDefault(import('../pages/push.vue' /* webpackChunkName: "pages/push" */))
const _10098df8 = () => interopDefault(import('../pages/regions.vue' /* webpackChunkName: "pages/regions" */))
const _7ebd8397 = () => interopDefault(import('../pages/reviews/index.vue' /* webpackChunkName: "pages/reviews/index" */))
const _72c92716 = () => interopDefault(import('../pages/saved-cards.vue' /* webpackChunkName: "pages/saved-cards" */))
const _32dfe183 = () => interopDefault(import('../pages/subscribe.vue' /* webpackChunkName: "pages/subscribe" */))
const _1366216c = () => interopDefault(import('../pages/unsubscribe.vue' /* webpackChunkName: "pages/unsubscribe" */))
const _4da9fe0a = () => interopDefault(import('../pages/osago/issuing-policy.vue' /* webpackChunkName: "pages/osago/issuing-policy" */))
const _b300b062 = () => interopDefault(import('../pages/osago/issuing-policy/car.vue' /* webpackChunkName: "pages/osago/issuing-policy/car" */))
const _ea5fcf06 = () => interopDefault(import('../pages/osago/issuing-policy/driver.vue' /* webpackChunkName: "pages/osago/issuing-policy/driver" */))
const _42f1be5e = () => interopDefault(import('../pages/osago/issuing-policy/issuing.vue' /* webpackChunkName: "pages/osago/issuing-policy/issuing" */))
const _6fad466e = () => interopDefault(import('../pages/osago/issuing-policy/owner.vue' /* webpackChunkName: "pages/osago/issuing-policy/owner" */))
const _d3dce312 = () => interopDefault(import('../pages/osago/pay.vue' /* webpackChunkName: "pages/osago/pay" */))
const _85c650a6 = () => interopDefault(import('../pages/osago/pay-success.vue' /* webpackChunkName: "pages/osago/pay-success" */))
const _374539db = () => interopDefault(import('../pages/search/fns.vue' /* webpackChunkName: "pages/search/fns" */))
const _410a3e20 = () => interopDefault(import('../pages/search/gibdd.vue' /* webpackChunkName: "pages/search/gibdd" */))
const _91433082 = () => interopDefault(import('../pages/widget/main-menu.vue' /* webpackChunkName: "pages/widget/main-menu" */))
const _0ba5f70c = () => interopDefault(import('../pages/widget/search.vue' /* webpackChunkName: "pages/widget/search" */))
const _01debcea = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _4cf5efd7 = () => interopDefault(import('../pages/shtrafy/show-category/_alias.vue' /* webpackChunkName: "pages/shtrafy/show-category/_alias" */))
const _21cb27b6 = () => interopDefault(import('../pages/shtrafy/_alias.vue' /* webpackChunkName: "pages/shtrafy/_alias" */))
const _4a61f494 = () => interopDefault(import('~/pages/email-landing' /* webpackChunkName: "" */))
const _ceaac300 = () => interopDefault(import('~/pages/index' /* webpackChunkName: "" */))
const _3877b402 = () => interopDefault(import('~/pages/unsubscribe' /* webpackChunkName: "" */))
const _762e098c = () => interopDefault(import('~/pages/reviews/index' /* webpackChunkName: "" */))
const _99e20fdc = () => interopDefault(import('~/pages/shtrafy/show-category/_alias' /* webpackChunkName: "" */))
const _451a169e = () => interopDefault(import('~/pages/shtrafy/_alias' /* webpackChunkName: "" */))
const _7fd6f58f = () => interopDefault(import('../node_modules/@simple-payments/shared-packages/modules/auto-payment/pages/unsubscribe.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/car-tax-landing",
    component: _05cbd3f5,
    name: "car-tax-landing"
  }, {
    path: "/cities",
    component: _78e00660,
    name: "cities"
  }, {
    path: "/confidential-policy",
    component: _4f0a4d90,
    name: "confidential-policy"
  }, {
    path: "/contact-us",
    component: _6ecc7202,
    name: "contact-us"
  }, {
    path: "/ctc-not-found",
    component: _6f10d446,
    name: "ctc-not-found"
  }, {
    path: "/divisions",
    component: _384108c2,
    name: "divisions"
  }, {
    path: "/email-landing",
    component: _1d16aac2,
    name: "email-landing"
  }, {
    path: "/error-gibdd-incoming",
    component: _2138da62,
    name: "error-gibdd-incoming"
  }, {
    path: "/expert",
    component: _96d63e7e,
    name: "expert"
  }, {
    path: "/gibdd-incoming",
    component: _d97b03ac,
    name: "gibdd-incoming"
  }, {
    path: "/guarantee",
    component: _02fa187e,
    name: "guarantee"
  }, {
    path: "/kak-oplatit",
    component: _99714b6c,
    name: "kak-oplatit"
  }, {
    path: "/o-servise",
    component: _42928b00,
    name: "o-servise"
  }, {
    path: "/osago",
    component: _d128203e,
    name: "osago"
  }, {
    path: "/payment",
    component: _107eac7f,
    name: "payment"
  }, {
    path: "/payment-fail",
    component: _7de72748,
    name: "payment-fail"
  }, {
    path: "/payment-history",
    component: _fb793c74,
    name: "payment-history"
  }, {
    path: "/payment-mobile",
    component: _6c44f260,
    name: "payment-mobile"
  }, {
    path: "/payment-success",
    component: _21af6db5,
    name: "payment-success"
  }, {
    path: "/profile",
    component: _76e67aa2,
    name: "profile"
  }, {
    path: "/push",
    component: _1079dd71,
    name: "push"
  }, {
    path: "/regions",
    component: _10098df8,
    name: "regions"
  }, {
    path: "/reviews",
    component: _7ebd8397,
    name: "reviews"
  }, {
    path: "/saved-cards",
    component: _72c92716,
    name: "saved-cards"
  }, {
    path: "/subscribe",
    component: _32dfe183,
    name: "subscribe"
  }, {
    path: "/unsubscribe",
    component: _1366216c,
    name: "unsubscribe"
  }, {
    path: "/osago/issuing-policy",
    component: _4da9fe0a,
    name: "osago-issuing-policy",
    children: [{
      path: "car",
      component: _b300b062,
      name: "osago-issuing-policy-car"
    }, {
      path: "driver",
      component: _ea5fcf06,
      name: "osago-issuing-policy-driver"
    }, {
      path: "issuing",
      component: _42f1be5e,
      name: "osago-issuing-policy-issuing"
    }, {
      path: "owner",
      component: _6fad466e,
      name: "osago-issuing-policy-owner"
    }]
  }, {
    path: "/osago/pay",
    component: _d3dce312,
    name: "osago-pay"
  }, {
    path: "/osago/pay-success",
    component: _85c650a6,
    name: "osago-pay-success"
  }, {
    path: "/search/fns",
    component: _374539db,
    name: "search-fns"
  }, {
    path: "/search/gibdd",
    component: _410a3e20,
    name: "search-gibdd"
  }, {
    path: "/widget/main-menu",
    component: _91433082,
    name: "widget-main-menu"
  }, {
    path: "/widget/search",
    component: _0ba5f70c,
    name: "widget-search"
  }, {
    path: "/",
    component: _01debcea,
    name: "index"
  }, {
    path: "/shtrafy/show-category/:alias?",
    component: _4cf5efd7,
    name: "shtrafy-show-category-alias"
  }, {
    path: "/shtrafy/:alias?",
    component: _21cb27b6,
    name: "shtrafy-alias"
  }, {
    path: "/:type(fssp|fns|gibdd)/landing",
    components: {
      default: _4a61f494
    },
    name: "old-email-landing"
  }, {
    path: "/email/:type(fssp|fns|gibdd)-landing",
    components: {
      default: _4a61f494
    },
    name: "email-landing"
  }, {
    path: "/:type(by-licence-plate|by-sts|by-vu|by-uin)",
    component: _ceaac300
  }, {
    path: "/email/unsubscribe",
    components: {
      default: _3877b402
    }
  }, {
    path: "/reviews/:page?",
    components: {
      default: _762e098c
    }
  }, {
    path: "/:alias(articles|pages)",
    components: {
      default: _99e20fdc
    }
  }, {
    path: "/:alias(articles|pages)/page/:page?",
    components: {
      default: _99e20fdc
    }
  }, {
    path: "/shtrafy/show-category/:alias(articles|pages)/:page?",
    components: {
      default: _99e20fdc
    }
  }, {
    path: "/:type(articles|pages)/:alias",
    components: {
      default: _451a169e
    }
  }, {
    path: "/autopayment/unsubscribe",
    component: _7fd6f58f,
    name: "autopayment-unsubscribe"
  }],

  parseQuery: (query) => require('qs').parse(query),
  stringifyQuery: (query) => {
    const result = require('qs').stringify(query);
    return result ? '?' + result : '';
  },
  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
